/*

  All top-level styling instruction here. All base styling of each HTML element can be found here.

*/

html {
  /* Sets 1rem default size to 10px */
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  font-size: 1.6rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button:focus {
  outline: 0 !important;
}

h1,
h2,
h3,
p,
blockquote,
pre {
  font-family: arial, sans-serif;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
}
h1 {
  font-size: 4.8rem;
  line-height: 2.64rem;
}
h2 {
  font-size: 3.2rem;
  line-height: 2.04rem;
}

h3 {
  font-size: 28px;
  line-height: 1.54rem;
}
p {
  font-size: 1.6rem;
  line-height: 2rem;
}
blockquote {
  font-size: 2.1rem;
  line-height: 3rem;
}
pre {
  font-size: 1.3rem;
  line-height: 1.85714rem;
}
